import { NgModule } from '@angular/core';

import { HomePageComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';

@NgModule({
  declarations: [HomePageComponent],
  imports: [HomeRoutingModule],
})
export class HomePageModule {}
